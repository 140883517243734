define("discourse/plugins/slack/discourse/pre-initializers/extend-category-for-slack", ["exports", "ember-addons/ember-computed-decorators", "discourse/models/category"], function (_exports, _emberComputedDecorators, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'extend-category-for-slack',
    before: 'inject-discourse-objects',
    initialize() {
      _category.default.reopen(dt7948.p({
        slack_channel: {
          get(channelField) {
            return channelField;
          },
          set(value) {
            this.set("custom_fields.slack_channel", value);
            return value;
          }
        }
      }, [["field", "slack_channel", [(0, _emberComputedDecorators.default)('custom_fields.slack_channel')]]]));
    }
  };
});